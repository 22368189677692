import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import LoadingComponent from "../loading/loading.component";
import { selectSignUpLoading } from "../../redux/user/user.selector";
import { signUpStart } from '../../redux/user/user.action';

import './sign-up.styles.scss';

const SignUp = ({ signUpStart, loading }) => {

    const [userCredentails, setUserCredentials] = useState({
        displayName:'',
        email:'',
        password:'',
        confirmPassword:''
    });

    const [signUpLoading, setSignUpLoading] = useState({
        loadingScreen: false
    });

    useEffect(() => {
        setSignUpLoading({loadingScreen: loading});
    }, [loading]);
    
    const { displayName, email, password, confirmPassword } = userCredentails;

    const handleSubmit = async event => {
        event.preventDefault();

        if(password !== confirmPassword) {
            alert("Password don't match");
            return;
        }
        signUpStart({displayName, email, password});
    }

    const handleChange = event => {
        const { name, value } = event.target;
        setUserCredentials({...userCredentails, [name]: value});
    }
    
    return(
        <div className='sign-up'>
            <h2 className='title'> I do not have a account</h2>
            <span>Sign up with your email and password</span>
            <form className='sign-up-form' onSubmit={handleSubmit}>
                <FormInput
                    type='text'
                    name='displayName'
                    value= {displayName}
                    onChange = { handleChange }
                    label= 'Display Name'
                    required
                />
                <FormInput
                    type='email'
                    name='email'
                    value= {email}
                    onChange = { handleChange }
                    label= 'Email'
                    required
                />
                <FormInput
                    type='password'
                    name='password'
                    value= {password}
                    onChange = { handleChange }
                    label= 'Password'
                    required
                />
                <FormInput
                    type='password'
                    name='confirmPassword'
                    value= {confirmPassword}
                    onChange = { handleChange }
                    label= 'Confirm Password'
                    required
                />
                {
                    signUpLoading.loadingScreen && <LoadingComponent data = {'SIGNING UP'}/>
                }
                <CustomButton type='submit'>SIGN UP</CustomButton>
            </form>
        </div>
    );
    }

const mapStateToProps = createStructuredSelector({
    loading: selectSignUpLoading
})
const mapDispatchToProps = dispatch => ({
    signUpStart: userCredentials => dispatch(signUpStart(userCredentials))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);