import { put, all, call, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { host } from '../../api.config';
import {unAuthorized} from '../../factory';
import OfferActionType from './offer.types';
import {
    getAllOfferSuccess, 
    getAllOfferFailure, 
    addOfferSuccess, 
    addOfferFailure,
    updateOfferSuccess,
    updateOfferFailure,
    deleteOfferSuccess,
    deleteOfferFailure,
} from './offer.action';

const MySwal = withReactContent(Swal);
export function* getOfferStart() {
    try {
        const offer = yield axios.get(`${host}/api/offer`);
        yield put(getAllOfferSuccess(offer.data));
    } catch (error) {
        let err = error.response.data;
        yield put(getAllOfferFailure(error));

        MySwal.fire({
            position: 'top-end',
            icon: 'error',
            title: err,
            showConfirmButton: false,
            timer: 1500
        });
        yield put(unAuthorized(error));
    }
}

export function* addOfferStart({payload}) {
    try {
        console.log(payload);
        const addedOffer = yield axios.post(`${host}/api/offer`, {payload});
        if(addedOffer.status === 200 || addedOffer.status === 201){
            yield put(addOfferSuccess(addedOffer.data));
            yield getOfferStart();
            MySwal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Offer Title Added Successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    } catch (error) {
        let err = error.response.data;
        MySwal.fire({
            position: 'top-end',
            icon: 'error',
            title: err,
            showConfirmButton: false,
            timer: 1500
        });
        yield put(addOfferFailure(error));
        yield put(unAuthorized(error));
    }
}

export function* updateOfferStart({payload: {id, status}}) {
    try {
        const updateOffer = yield axios.put(`${host}/api/offer/${id}`, {status});
        if(updateOffer.status === 200 || updateOffer.status === 201){
            yield put(updateOfferSuccess(updateOffer.data));
            yield getOfferStart();
            MySwal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Offer Title Updated Successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    } catch (error) {
        let err = error.response.data;
        MySwal.fire({
            position: 'top-end',
            icon: 'error',
            title: err,
            showConfirmButton: false,
            timer: 1500
        });
        yield put(updateOfferFailure(error));
        yield put(unAuthorized(error));
    }
}

export function* deleteOfferStart({payload}) {
    try {
        const id = payload;
        const deleteOffer = yield axios.delete(`${host}/api/offer/${id}`);
        if(deleteOffer.status === 200 || deleteOffer.status === 201){
            yield put(deleteOfferSuccess(deleteOffer.data));
            yield getOfferStart();
            MySwal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Offer Title Deleted Successfully.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    } catch (error) {
        let err = error.response.data;
        MySwal.fire({
            position: 'top-end',
            icon: 'error',
            title: err,
            showConfirmButton: false,
            timer: 1500
        });
        yield put(deleteOfferFailure(error));
        yield put(unAuthorized(error));
    }
}

export function* deletionConfirmation() {
    let confirmed = false;
    yield Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
    if (result.isConfirmed) {
        confirmed = true;
    }
    });
    return confirmed;
}
export function* deleteOfferConfirmation({payload}) {
    const confirmed = yield deletionConfirmation();
    if(confirmed) yield deleteOfferStart({payload});
}

export function* onOfferGetStart() {
    yield takeLatest(OfferActionType.OFFER_GET_START, getOfferStart);
}

export function* onOfferAddStart() {
    yield takeLatest(OfferActionType.OFFER_ADD_START, addOfferStart);
}

export function* onOfferUpdateStart() {
    yield takeLatest(OfferActionType.OFFER_UPDATE_START, updateOfferStart);
}

export function* onOfferDeleteStart() {
    yield takeLatest(OfferActionType.OFFER_DELETE_START, deleteOfferConfirmation);
}

export function* OfferSaga(){
    yield all([
        call(onOfferGetStart),
        call(onOfferAddStart),
        call(onOfferUpdateStart),
        call(onOfferDeleteStart)
    ]);
}