import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";

import { selectIsOfferFetching } from "../../redux/offer/offer.selector";
import WithSpinner from "../with-spinner/with-spinner.component";
import OfferList from "./admin-offer-list.component";

const mapStateToProps = createStructuredSelector({
    isLoading: selectIsOfferFetching
});

const OfferTitleListContainer = compose(
    connect(mapStateToProps),
    WithSpinner
)(OfferList);

export default OfferTitleListContainer;