import React from 'react';

const SvgWD = (prop) => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="27%" viewBox="0 0 896 896" enableBackground="new 0 0 896 896">
<path fill="white" opacity="1.000000" stroke="none" 
	d="
M273.898804,664.947510 
	C269.884094,651.930969 264.750000,639.721741 261.040039,626.986816 
	C259.266296,620.898254 254.982452,615.567444 255.268906,608.721191 
	C255.305237,607.852722 254.604752,606.778320 253.945084,606.085388 
	C250.651535,602.625916 249.575577,598.440369 249.308853,593.809753 
	C249.074310,589.737854 246.234512,586.449585 245.163300,582.761780 
	C241.191528,569.088196 234.814331,556.273804 230.977585,542.625671 
	C227.613647,530.659424 222.644104,519.325500 218.794495,507.598297 
	C215.610062,497.897400 211.485382,488.429596 209.684769,478.281860 
	C209.511719,477.306610 209.621887,476.144165 209.120422,475.386383 
	C205.873245,470.479614 204.531937,464.974121 202.793854,459.421478 
	C200.683517,452.679565 198.971863,445.555054 195.988480,439.300842 
	C191.165359,429.189972 188.167587,418.567688 184.959824,408.031708 
	C182.413406,399.667938 177.590118,392.054504 176.846893,383.111908 
	C176.635132,380.563995 175.024765,378.495941 173.796738,376.326172 
	C173.144485,375.173706 171.940933,374.042145 171.909576,372.876282 
	C171.693832,364.852051 167.218979,358.156036 165.164780,350.705597 
	C162.313828,340.365417 157.819809,330.553223 154.102371,320.502136 
	C150.733124,311.392517 147.089294,302.233246 145.322205,292.566528 
	C144.777008,289.584137 143.874268,286.922516 141.738998,284.557098 
	C138.902435,281.414764 138.871567,277.365082 140.336975,273.731903 
	C141.759766,270.204407 145.454010,270.504333 148.612366,270.488647 
	C178.585724,270.339478 208.565735,269.778595 238.531052,270.214661 
	C275.191162,270.748077 311.846222,270.554352 348.503601,270.570221 
	C357.810272,270.574249 367.118835,270.333618 376.423309,270.085602 
	C385.039429,269.855957 388.252869,274.850983 385.106018,282.990112 
	C381.981323,291.071930 377.559418,298.536743 377.695557,308.003967 
	C377.766510,312.936798 373.275360,318.029114 370.516571,322.862030 
	C369.429993,324.765625 367.247284,325.286133 364.843964,325.283203 
	C328.347321,325.238556 291.841278,325.756653 255.360367,325.014587 
	C246.147156,324.827179 236.914780,326.857147 227.659622,324.779510 
	C223.931000,323.942474 221.962280,326.898987 223.956985,330.290283 
	C228.977676,338.826111 231.004959,348.540436 233.764572,357.711609 
	C236.224304,365.886169 241.159348,373.193115 241.947281,381.962921 
	C242.280228,385.668732 243.750229,389.427734 246.103958,392.562531 
	C248.659012,395.965515 247.703690,400.349152 249.395355,403.947540 
	C254.047409,413.843079 256.951141,424.421051 260.272369,434.731598 
	C262.957611,443.067749 266.286621,451.174225 269.115326,459.433014 
	C272.903748,470.493866 277.001068,481.486694 280.956909,492.509247 
	C283.901855,500.714935 286.259125,509.129700 289.087311,517.379578 
	C293.041718,528.914429 297.052032,540.423462 300.790558,552.035583 
	C302.601562,557.660889 306.035034,562.854675 307.526123,569.035156 
	C309.428284,561.153137 315.710785,555.028442 314.832520,546.552246 
	C314.766266,545.912903 314.795258,545.085999 315.141327,544.607910 
	C321.558258,535.743042 320.040741,523.955994 326.109528,514.958984 
	C328.175140,511.896637 326.865082,507.925507 328.194641,504.829041 
	C332.318970,495.223785 333.773010,484.725983 338.432098,475.308502 
	C339.662567,472.821411 340.153625,469.933685 340.760101,467.180542 
	C341.763428,462.626007 342.523315,458.001526 345.472870,454.171112 
	C347.609711,451.395996 346.437042,448.031097 347.132782,444.975006 
	C348.437347,439.244507 351.986877,434.502106 353.250977,428.689484 
	C356.095520,415.609375 361.842224,403.307953 364.953735,390.357422 
	C367.318604,380.514465 372.298645,371.554596 373.611908,361.455688 
	C373.907501,359.182220 374.841919,356.979187 376.207947,355.016388 
	C376.963470,353.930817 378.017334,352.703613 378.048157,351.517181 
	C378.339996,340.285492 383.984833,330.476227 387.231720,320.185303 
	C390.285828,310.505402 391.809692,300.176361 396.634460,291.051422 
	C397.902222,288.653717 398.252197,286.027985 399.119080,283.561066 
	C403.330841,271.575378 407.070038,259.343048 410.437653,247.208069 
	C413.263062,237.026611 418.772125,227.673325 419.775208,216.971848 
	C419.956879,215.033386 420.472473,212.892868 421.535919,211.320389 
	C423.568146,208.315460 424.383545,205.159302 424.959106,201.613449 
	C425.588379,197.736435 427.205688,193.966675 428.765656,190.309387 
	C430.212311,186.917786 431.515686,183.601685 431.823608,179.849228 
	C432.066284,176.891953 432.932159,174.011719 435.134216,171.682922 
	C436.618958,170.112778 437.366882,168.160904 437.867767,165.939560 
	C440.128296,155.914230 445.639374,146.923706 447.221222,136.623077 
	C447.675507,133.664963 450.511749,132.296356 453.362030,132.619568 
	C456.651428,132.992599 460.416199,133.722275 461.104492,137.727905 
	C462.947449,148.453323 467.450317,158.288483 471.438263,168.217453 
	C474.652252,176.219482 476.940460,184.520370 480.000702,192.564087 
	C483.840576,202.657059 486.961975,213.148865 489.577423,223.753265 
	C491.534821,231.689560 494.892426,239.300079 497.241089,247.214691 
	C499.015289,253.193359 500.757324,259.271454 502.922333,265.072418 
	C506.227386,273.928253 508.271545,283.200470 512.128601,291.842377 
	C513.832520,295.660065 515.082458,299.533600 515.498413,303.777832 
	C515.832947,307.192047 516.749939,310.654327 519.915955,312.931366 
	C522.096191,314.499512 521.957825,317.074432 521.965332,319.343414 
	C521.983398,324.747681 522.809814,329.783386 525.580872,334.631714 
	C527.714783,338.365356 528.223938,342.789246 528.721619,347.119537 
	C529.360474,352.678162 533.285950,356.998962 534.756958,362.297760 
	C537.473022,372.081207 539.433533,382.225067 543.469177,391.448547 
	C547.405884,400.445801 549.452515,409.998413 552.459839,419.155945 
	C555.770081,429.236053 561.297913,438.870941 561.608765,450.032745 
	C561.702637,453.404785 565.260681,456.620972 565.567993,460.057373 
	C566.561707,471.168060 571.004761,481.455505 575.045044,491.276947 
	C579.209473,501.400269 581.366211,512.094177 585.149597,522.258057 
	C588.414490,531.029053 591.323425,540.526978 593.231506,549.984497 
	C594.555603,556.546936 598.527893,561.905151 599.880676,568.705627 
	C601.002502,567.935974 601.643799,567.740479 601.737000,567.396484 
	C604.593506,556.847778 610.024841,547.117188 612.765808,536.721252 
	C615.175293,527.582581 618.640320,518.764526 621.324280,509.777924 
	C623.832397,501.380310 626.595947,492.691864 630.457703,484.639679 
	C632.295776,480.807190 632.377258,476.321594 634.247498,472.946289 
	C639.390564,463.664307 640.624268,453.136261 644.822815,443.550476 
	C647.893738,436.539062 649.826172,428.905121 652.329895,421.568939 
	C654.197449,416.097076 656.538391,410.751740 658.125977,405.293396 
	C660.896240,395.768982 665.409424,386.925171 667.818542,377.254486 
	C669.883057,368.966980 672.110779,360.567200 676.289795,352.936615 
	C678.224915,349.403137 679.454285,345.574371 680.216187,341.672028 
	C680.986755,337.724915 681.901733,333.907196 683.826172,330.337402 
	C685.492371,327.246704 684.614746,325.860870 680.911682,325.731995 
	C641.666992,324.366089 602.414917,325.372955 563.169922,325.526581 
	C556.580505,325.552368 550.054321,324.209930 543.352905,325.252502 
	C539.605286,325.835541 535.932373,323.690063 534.685547,319.242859 
	C532.316528,310.793671 529.071045,302.560181 527.889709,293.821411 
	C527.310730,289.538513 524.174255,286.519867 523.273315,282.343445 
	C521.341797,273.388916 524.496338,269.080597 533.375427,270.276672 
	C539.754578,271.136047 546.010559,269.976715 552.322510,270.079651 
	C617.459351,271.141571 682.601196,270.459503 747.740540,270.641083 
	C752.382568,270.654022 757.029358,270.392426 761.666077,270.526917 
	C766.827820,270.676636 768.966614,273.652588 768.420593,278.800720 
	C767.906189,283.649597 764.424561,287.151306 763.113281,291.634735 
	C759.728882,303.206146 755.254639,314.417053 751.350220,325.785370 
	C748.269775,334.754272 743.251038,343.396881 743.187073,353.352783 
	C743.179932,354.460876 742.718445,355.654144 742.184448,356.657166 
	C736.826782,366.722107 734.331360,377.847931 730.401917,388.439636 
	C729.191406,391.702637 728.468079,395.142365 726.732727,398.218140 
	C724.917358,401.435699 723.752747,404.847137 723.448547,408.575378 
	C723.329956,410.029053 722.960205,411.604736 722.226624,412.841095 
	C719.051331,418.192261 718.697205,424.745514 715.999451,429.902588 
	C711.106567,439.255646 710.139771,449.684509 705.912537,459.262848 
	C701.956116,468.227661 698.624939,477.857697 695.965759,487.605621 
	C692.015259,502.087189 685.859619,515.960022 680.809021,530.151184 
	C677.534119,539.352905 674.574219,548.666443 671.439697,557.918457 
	C667.987671,568.107422 664.668945,578.347290 660.913635,588.423767 
	C659.857422,591.257812 659.307861,594.162109 658.409729,596.998779 
	C658.211609,597.624695 658.443787,598.487244 658.093445,598.949463 
	C652.724609,606.032593 653.129517,615.271667 649.123047,622.815430 
	C647.753479,625.394287 647.391235,628.170837 646.546387,630.835022 
	C641.947144,645.338379 635.874329,659.345764 632.024475,674.101685 
	C631.612305,675.681396 630.771057,677.206787 629.871704,678.590271 
	C627.754944,681.846802 626.500549,685.202026 626.093079,689.205933 
	C625.700134,693.067871 624.584595,697.032776 622.141357,700.382690 
	C620.346863,702.843201 619.366211,705.657959 619.109070,708.722839 
	C619.012573,709.872986 618.999023,711.156555 618.476257,712.124817 
	C613.159058,721.973511 611.557068,733.160583 607.163879,743.338196 
	C606.651062,744.526245 606.418762,745.888184 606.313232,747.190308 
	C605.813354,753.358521 604.395081,754.909973 598.617004,755.381958 
	C592.647278,755.869568 589.890625,754.376343 589.262817,748.404175 
	C587.610596,732.685120 580.174133,718.752930 576.286865,703.782410 
	C573.413940,692.718384 569.083496,682.161194 566.350281,671.030762 
	C564.216125,662.339783 561.053894,653.711792 558.379028,645.099609 
	C555.058472,634.408569 551.470459,623.738281 547.995728,613.124878 
	C545.185120,604.539551 543.148560,595.746887 540.211975,587.241394 
	C535.794250,574.445740 532.400452,561.274231 527.441101,548.731567 
	C523.947083,539.894714 523.702698,530.092529 518.693298,521.874084 
	C516.074585,517.577820 517.266846,512.384155 515.142822,508.063721 
	C510.369965,498.355133 507.593842,487.772797 504.883636,477.570007 
	C502.341034,467.998077 499.167389,458.438812 495.819702,449.387054 
	C492.045166,439.181061 490.237396,428.460846 486.348022,418.257935 
	C482.318665,407.687836 477.321991,396.844879 476.636353,385.067657 
	C476.544678,383.493530 475.847046,382.207855 475.044678,380.927704 
	C472.159943,376.325073 471.459076,370.994446 469.814270,365.965759 
	C466.580933,356.080536 464.784943,345.741302 460.441681,336.207397 
	C458.944580,332.921234 457.205139,329.712463 457.586639,325.797699 
	C457.841827,323.179138 456.584595,321.010040 453.900757,319.681427 
	C451.302032,321.187378 450.293060,323.602753 450.395813,326.342987 
	C450.560181,330.725708 448.922638,334.470306 446.848846,338.116913 
	C445.688934,340.156494 444.731812,342.224915 444.334534,344.586609 
	C443.278442,350.865143 443.416260,357.545349 438.261383,362.499115 
	C437.561584,363.171631 437.552612,364.654877 437.406769,365.786346 
	C437.258514,366.936310 437.683319,368.261902 437.261932,369.259125 
	C433.187378,378.902374 431.519470,389.296295 427.892242,399.051514 
	C426.513000,402.760895 424.455566,406.133301 424.202484,410.276611 
	C423.861816,415.854370 420.410431,420.499298 419.056458,425.817963 
	C415.594238,439.418427 410.261627,452.465546 406.494537,465.919220 
	C403.689514,475.936890 399.473145,485.524933 397.553131,495.776947 
	C397.018982,498.629150 395.828949,501.175537 394.361877,503.584320 
	C393.027344,505.775574 391.966705,508.131195 391.904816,510.586548 
	C391.766235,516.081543 388.663147,520.733704 387.697601,525.748718 
	C385.638824,536.442078 380.318573,546.071655 378.644012,556.806946 
	C378.160797,559.904663 377.105316,562.887146 375.426605,565.667175 
	C373.632019,568.638977 372.045441,571.786316 372.236298,575.468201 
	C372.270172,576.121033 372.245178,576.889404 371.939697,577.430725 
	C366.847473,586.453857 366.631683,597.197388 361.738800,606.278442 
	C360.375031,608.809570 359.390381,611.420349 359.347046,614.334473 
	C359.334717,615.164246 359.430603,616.084656 359.117096,616.807495 
	C354.084137,628.411743 351.668671,640.879028 347.543243,652.802124 
	C344.290680,662.202393 341.048157,671.833618 338.446808,681.592529 
	C335.319855,693.323059 331.738373,705.045349 327.783905,716.615356 
	C324.282654,726.859253 319.048828,736.661438 318.488037,747.927673 
	C318.253296,752.643188 314.418243,755.436462 309.681549,756.204407 
	C306.414093,756.734131 304.445953,754.701538 302.803864,752.185913 
	C301.927856,750.843872 301.735657,749.438477 301.826141,747.905396 
	C302.015900,744.688904 301.623779,741.515015 300.058105,738.691711 
	C295.676605,730.790833 293.877228,721.904358 290.763672,713.548035 
	C287.324768,704.318542 284.073303,694.901672 281.272705,685.367371 
	C279.299286,678.649048 276.569031,671.961121 273.898804,664.947510 
z"/>
</svg>
);

export default SvgWD;