import React from "react";
import { Carousel } from "react-responsive-carousel";

const CrouselImageContainerComponent = ({ bannerType }) => {
    return (
        <Carousel
            showArrows={true}
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            dynamicHeight={true}
            showStatus={false}
        >
            {bannerType.length !== 0 &&
                bannerType.map((imageUrl) => {
                    return (
                        <div key={imageUrl._id}>
                            <img
                                className="imageSize"
                                src={imageUrl.title}
                                loading="lazy"
                                alt="banner_img"
                            />
                        </div>
                    );
                })}
        </Carousel>
    );
};

export default CrouselImageContainerComponent;
